import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Input, FormGroup } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "form-group"
    }}>{`Form Group`}</h1>
    <p>{`각종 컨트롤러들을 한 폼으로 묶어줍니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={FormGroup} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<FormGroup label=\"Label\">\n  <Input placeholder=\"Placeholder text\" />\n</FormGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      FormGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormGroup label="Label" mdxType="FormGroup">
    <Input placeholder="Placeholder text" mdxType="Input" />
  </FormGroup>
    </Playground>
    <h2 {...{
      "id": "with-different-label-colors"
    }}>{`With different label colors`}</h2>
    <Playground __position={2} __code={'<FormGroup label=\"Label\" labelColor=\"gray\">\n  <Input placeholder=\"Placeholder text\" />\n</FormGroup>\n<FormGroup label=\"Label\" labelColor=\"pink\">\n  <Input placeholder=\"Placeholder text\" />\n</FormGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      FormGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormGroup label="Label" labelColor="gray" mdxType="FormGroup">
    <Input placeholder="Placeholder text" mdxType="Input" />
  </FormGroup>
  <FormGroup label="Label" labelColor="pink" mdxType="FormGroup">
    <Input placeholder="Placeholder text" mdxType="Input" />
  </FormGroup>
    </Playground>
    <h2 {...{
      "id": "inline-form-group"
    }}>{`Inline form group`}</h2>
    <Playground __position={3} __code={'<FormGroup label=\"Label\" inline>\n  <Input placeholder=\"Placeholder text\" />\n</FormGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      FormGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormGroup label="Label" inline mdxType="FormGroup">
    <Input placeholder="Placeholder text" mdxType="Input" />
  </FormGroup>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      